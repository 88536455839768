.NavBar {
  #navLinks {
    display: none;
    justify-content: space-around;
    margin: 0 3em 0 0em;
  }

  .navbar {
    margin-top: 5px;
    height: 10vh;
  }

  .navToButton {
    padding: 5px;
  }

  #menu {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  #Menu-X {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    #navLinks {
      display: none;
      margin: 0;
    }
    .navbar {
      height: auto;
      display: block;
    }
    .navToText {
      font-size: 12px;
      text-align: center;
      display: block;
    }
  }
}
