.Links {
  padding: 30px;
  width: 90vw;
  margin: auto;

  .linksBox {
    height: 78vh;
    overflow: hidden auto;
  }

  .linksContents {
    border-radius: 2em;
    padding: 1em;
    padding-left: 2em;
    margin: 1em;
  }

  .linksWords {
    display: block;
  }

  @media only screen and (max-width: 700px) {
    padding: 10px;
    height: 68vh;
    font-size: 12px;

    .linkContents {
      border-radius: 0;
      padding: 0;
      margin: 10px 0 0 0;
      font-size: 12px;
    }
    .code {
      white-space: break-spaces;
    }
    .linksWords,
    .code {
      font-size: 12px;
    }
    .h4 {
      font-size: 14px;
    }
    .h3 {
      font-size: 16px;
    }
    .h2 {
      font-size: 18px;
    }
    .h1 {
      font-size: 20px;
    }
  }
}
