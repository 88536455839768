.App {
  overflow: hidden;
}
.ModelViewer {
  margin: auto;

  .content {
    margin: auto;
    overflow: hidden;
    text-align: center;
  }
  #modelContainer {
    overflow: hidden;
    display: block;
    position: absolute;
  }
  #densityValue,
  #diameterValue {
    width: 6ch;
  }
  #costKilogramValue,
  #printSpeedValue {
    width: 7ch;
  }
  #calcContainer {
    text-align: left;
    height: 80vh;
    width: fit-content;
    margin: auto;
    padding-top: 5px;
  }
  #calcContents {
    display: block;
    position: inherit;
    #densityValue,
    #costKilogramValue,
    #diameterValue,
    #printSpeedValue {
      background: rgba(0, 0, 0, 0);
      border-color: #ff0000;
      color: #ff0000;
    }
  }
  .calcButtons {
    text-align: center;
    margin: 5px;
  }
  .leftitem {
    float: left;
    margin-left: 2px;
  }
  .buttonChanger {
    margin: 1px;
  }
  .modelButtons {
    margin: 5px 0;
  }
  .separator {
    margin-top: 20px;
  }
  .loading_splash {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 9999;
  }
  .loading_splash_container {
    position: fixed;
    width: 64px;
    height: 64px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    border: 0;
  }
  .lds-spinner {
    color: white;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: white;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media only screen and (max-width: 700px) {
    font-size: 12px;
    #calcContainer {
      width: 80vw;
    }
    .h4 {
      font-size: 14px;
    }
    .h3 {
      font-size: 16px;
    }
    .h2 {
      font-size: 18px;
    }
    .h1 {
      font-size: 20px;
    }
  }
}
