.App {
  text-align: center;
  margin-top: -10px;
  display: block;
  position: absolute;
  height: 100vh;
  width: 100vw;

  .footer {
    visibility: visible;
  }

  .footerContents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1vh;
  }

  @media only screen and (max-width: 700px) {
    height: 100%;
    width: 100%;

    .footer {
      visibility: hidden;
    }
  }
}
