.popup-content {
  margin: auto;
  background: #fff;
  padding: 5px;
  border: 1px solid #d7d7d7;
}
[role='tooltip'].popup-content {
  width: 200px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
.popup-arrow {
  -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, 0.16));
  color: #fff;
  stroke-width: 2px;
  stroke: #d7d7d7;
  stroke-dasharray: 30px;
  stroke-dashoffset: -54px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.react-pdf__Page__canvas {
  width: max-content !important;
  height: max-content !important;
  max-width: 100%;
  max-height: 100%;
  background: #808080;
}

.textButtons {
  display: flex;
}

.exit-and-dl {
  width: 50%;
  text-align: left;
}

.pdfButtons {
  width: 50%;
  text-align: center;
}

.textContainer {
  display: block;
  unicode-bidi: embed;
  font-family: monospace;
  white-space: pre-wrap;
  max-height: 85vh;
  overflow: hidden auto;
  width: 85vh;
  text-align: center;
}

.popupContents {
  height: 78vh;
}
